<template>
  <v-container fluid class="mt-4 ml-2 pr-6">
    <!--  -->
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title class="text-subtitle-1">
            Expediente empleados
            <v-spacer></v-spacer>
            <!-- Boton para descargar el excel -->
            <v-btn
              class="mr-2"
              small
              dark
              color="green"
              @click="exportExcel(filterNomina, 'Egresos')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
		          color="orange"
		          dark
		          class="mr-2"
		          @click="dialogArchivos.estatus = true"
		          small
		          tile
		        >
		          <v-icon left small>mdi-plus</v-icon>
		          Cat. Archivos
		        </v-btn>

            <v-btn color="primary" @click="initialize()" small tile>
              <v-icon small left>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="8" lg="6">
                <label>Selecciona un empleado</label>
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="empleado"
                  :items="empleados"
                  label="Selecciona un usuario"
                  prepend-inner-icon="mdi-account"
                  hide-details
                  single-line
                  item-text="nombre_completo"
                  item-value="id_usuario"
                >
                	<template v-slot:append-outer>
                    <v-btn
                      small
                      color="primary"
                      tile
                      @click="dialogAddArchivo = true"
                    >
                      <v-icon left small>
                        mdi-plus
                      </v-icon>
                      Agregar archivo
                    </v-btn>
			            </template>
                </v-autocomplete>
              </v-col>
						</v-row>

            <v-row justify="space-between">

            	<v-col cols="12" md="4" lg="2">
                <v-btn 
                	color="primary" 
                	small 
                	v-if="archivosZip.length"
                	tile
                	@click="descargarZip()"
                >
              		Descargar
              	</v-btn>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  filled
                  single-line
                  label="Búscar"
                  append-icon="mdi-magnify"
                  hide-details
                  v-model="buscar"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- REQUISICIONES -->
            <v-data-table
              :headers="headers"
              :items="datos"
              dense
              show-select
              class="elevation-0 mt-4"
              :search="buscar"
              v-model="archivosZip"
              item-key="idarchivos_empleado"
            >
            	<!-- Datos dinámicos de las tablas -->
				      <template v-slot:item.actions="{ item }">
		            <v-btn
		              color="primary"
		              dark
		              x-small
		              tile
		              @click="deleted( item )"
		              class="mr-2"
		            >
		              <!-- v-if="item.idcontador == 0" -->
		              <v-icon small>
		                mdi-delete
		              </v-icon>
		            </v-btn>

		            <v-btn
		              color="red"
		              dark
		              x-small
		              tile
		              @click="verPDF(item)"
		            >
		              <v-icon small>
		               	mdi-file-pdf-box
		              </v-icon>
		            </v-btn>

				      </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialogAddArchivo"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">Agrgar archivo</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>

      		<label>Selecciona un usuario</label>
        	<v-autocomplete
            filled
            dense
            clearable
            v-model="empleado"
            :items="empleados"
            label="Selecciona un usuario"
            prepend-inner-icon="mdi-account"
            single-line
            item-text="nombre_completo"
            item-value="id_usuario"
          >
          </v-autocomplete>

      		<label>Tipo de archivos</label>
          <v-autocomplete
            filled
            dense
            clearable
            v-model="archivo"
            :items="archivos"
            label="Selecciona un tipo de arrchivo"
            prepend-inner-icon="mdi-file"
            single-line
            item-text="archivo"
            item-value="idarchivos"
          >
          </v-autocomplete>

          <div v-if="!file">
            <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
              <div class="dropZone-info" @drag="onChange">
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
                <div class="dropZone-upload-limit-info">
                  <div>Extensión: png, jpg, jpeg, svg</div>
                  <div>Tamaño máximo: 10 MB</div>
                </div>
              </div>
              <input type="file" @change="onChange">
            </div>
          </div>


          <v-btn  v-else color="red" class="mb-2" dense @click="file = null, vistaPrevia = null, extension = ''" fab small dark><v-icon>mdi-delete</v-icon></v-btn>
          <v-img :src="vistaPrevia.url" v-if="vistaPrevia && ['jpg', 'png', 'jpeg', 'JPG'].includes( vistaPrevia.extension )" contain max-height="300"/>
          <embed :src="vistaPrevia.url" v-if="vistaPrevia && ['PDF', 'pdf'].includes( extension )" type="application/pdf" width="100%" height="400px"/> 
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            dark
            small
            @click="dialogAddArchivo = false"
            tile
            dense
          >
          	<v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            dark
            @click="save"
            tile
          >
          	<v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VER PDF -->
    <v-dialog v-model="dialogPDF" max-width="650px" persistent>
      <v-card class="elevation-0">
        <!-- Imagen -->
        <embed :src="url_servidor + pdf.documento" width="650" height="700" type="application/pdf" v-if="pdf && ['PDF', 'pdf'].includes( extension )">
        <v-img :src="url_servidor + pdf.documento" v-if="pdf && ['jpg', 'png', 'jpeg', 'JPG'].includes( extension )" contain max-height="300"/>
        <v-card-actions class="pt-0" >
          <v-spacer></v-spacer>
          <!-- Guardar la información  -->
          <v-btn
            color="error" 
            dark 
            class="elevation-6" 
            block
            @click="dialogPDF = false"
          >Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Ver formulario -->
    <CatArchivos    
      v-if="dialogArchivos.estatus"    
      :dialogArchivos="dialogArchivos" 
      @getArchivos="getArchivos"
    />

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />

  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";

import { saveAs } from 'file-saver';

import CatArchivos from "@/components/rh/CatArchivos.vue";


// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  components: {
    Alerta,
    carga,
    CatArchivos
  },

  mixins: [validarErrores, funcionesExcel],

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    empleados:[],
    empleado:null,

    archivosZip:[],

    dialog: false,
    dialogDelete: false,
    dialogVer: false,

    pdf: null,
		dialogPDF: false,
    
    buscar:'',
    headers: [
      { text: "ID"             , value: "idarchivos_empleado", sortable: false },
      { text: "Empleado"       , value: "nombre_completo"      },
      { text: "Tipo de archivo", value: "archivo"              },
      { text: "Actions"        , value: "actions"              },
    ],

    datos:[],

    dialogArchivos:{
    	estatus: false,
    },

    archivos: [],
    archivo:null,

    dialogAddArchivo: false,
    usuarioSeleccionado: null,

    archivos:null,
    vistaPrevia: null,
    file: '',
    dragging: false,
    dialgImagen:false,
		url_servidor: '',
		fullscreen: false,
		pdf:null,
		dialogPDF: false,
		
		imagen: null,
		extension:'',
		archivoSeleccionado: null,

  }),

  watch:{

  	empleado( value ){
  		if( value ){ this.initialize() }
  	}

  },

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
  },

  async created() {
  	this.url_servidor = axios.defaults.baseURL + "archivos_empleados/";

    await this.getEmpleados();
    await this.getArchivos();
  },

  methods: {

  	initialize(){
			this.datos = [];
      this.cargar = true;

      const payload = {
      	idempleados: this.empleado
      }

      return this.$http.post("usuarios.get_archivos_empleado", payload ).then((response) => {
        this.datos = response.data.archivos;
        this.cargar = false;
      })
      .catch((error) => {
        this.validarError(error);
      })
      .finally(() => {
        this.cargar = false;
      });
  	},

    getEmpleados() {
      this.empleados = [];
      this.cargar = true;

      return this.$http.get("usuarios.all.erp").then((response) => {
        this.empleados = response.data;
        this.cargar = false;
      })
      .catch((error) => {
        this.validarError(error);
      })
      .finally(() => {
        this.cargar = false;
      });
    },

    getArchivos( ){
    	this.cargar = true
      this.archivos = []
      this.dialogArchivos.estatus = false
      return this.$http.get('usuarios.get_archivos').then(response=>{
      	this.archivos = response.data.archivos
      	this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    deleted ( value ){
    	this.cargar = true

    	value.deleted = 1 
      return this.$http.put('usuarios.update_archivo_empleado/' + value.idarchivos_empleado, value ).then(response=>{
      	this.validarSuccess('Datos cargados correctamente')
      	this.vistaPrevia      = null
      	this.file             = null
      	this.dialogAddArchivo = false
      	this.archivo          = null
      	this.cargar           = false
      	this.initialize()
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    async save(){
    	let nombre_archivo = ''

    	if( this.file ){
    		nombre_archivo = await this.grabarImagen( )
    	}

    	const payload = {
    		idarchivos: this.archivo, 
    		idempleados: this.empleado, 
    		documento: nombre_archivo
    	}

    	this.cargar = true
      return this.$http.post('usuarios.add_archivo_empleado', payload ).then(response=>{
      	this.validarSuccess('Datos cargados correctamente')
      	this.vistaPrevia      = null
      	this.file             = null
      	this.dialogAddArchivo = false
      	this.archivo          = null
      	this.cargar           = false
      	this.initialize()
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })

    	console.log( payload )
    },

    verPDF( value ){
    	let extension = value.documento.split('.')
      this.pdf         = value
      this.extension   = extension[extension.length - 1]
      this.dialogPDF   = true
    },

    descargarZip ( ){
    	console.log( this.archivosZip )
    	
    	const payload = {
    		archivos: this.archivosZip,
    		idempleados: this.empleado
    	}

    	this.cargar = true
      return this.$http.post('usuarios.download_archivos', payload ).then(response=>{

      	window.open( axios.defaults.baseURL + 'archivos_empleados/' + this.empleado +'.zip')

      	this.validarSuccess('Datos cargados correctamente')
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })


    },

    getBase64(file) {
      var me = this
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {

      	let extension = file.name.split('.')

        me.vistaPrevia = {
          url: reader.result, 
          image_name: file.name,
          extension: extension[extension.length - 1]
        }
      };
    },
 
		onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }
      
      this.createFile(files[0]);
    },

    createFile(file) {
      if (file.size > 40000000) {
        alert('please check file size no over 40 MB.')
        this.dragging = false;
        return;
      }
      
      let extension = file.name.split('.')
      
      this.file = file;
      this.extension = extension[extension.length - 1]
      this.getBase64(this.file)
      this.dragging = false;
    },


    grabarImagen( ){
      return new Promise((resolve,reject)=>{
        let formData = new FormData();
        //se crea el objeto y se le agrega como un apendice el archivo
        formData.append("file", this.file);

        this.$http.post("usuarios.add_archivo_servidor", formData).then( response => {
          resolve( response.data.nombre ) 
        }).catch( error =>{
          reject( error )
        }).finally( () => { this.cargar = false })
      })
    },

  },
};
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>